body {
  background-color: #f5f5f5;
  margin: 0;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul {
  padding-left: 2rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a.logo-text {
  color: #1d1d1f !important;
  font-size: 21px !important;
  line-height: 1.1904761904761905 !important;
  font-weight: 500 !important;
  letter-spacing: 0.02px !important;
  margin-left: 0 !important;
}
.progress {
  height: 5px;
  margin-top: 30px;
  overflow: hidden;
  background-color: #d2d2d7;
  border-radius: 5px;
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  border-radius: 5px;
  background-color: #1d1d1f;
}

.heading {
  color: #1d1d1f;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
}

.item-heading {
  color: #1d1d1f;
  font-size: 32px;
  line-height: 1.125;
  font-weight: 600;
  letter-spacing: 0.004em;
  margin-top: 0px;
}

.description-content {
  color: #1d1d1f;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.001em;
}

.mini-description-content {
  margin-left: 0px;
  color: #1d1d1f;
  font-size: 12px;
  line-height: 1.33337;
  letter-spacing: -0.01em;
}

hr {
  border: 0.5px solid #d2d2d7;
}

.progress-text {
  font-size: 12px;
  line-height: 1.33337;
  letter-spacing: -0.01em;
}

.MuiPaper-rounded {
  min-width: 25vw;
  border-radius: 12px !important;
}

.MuiCollapse-root .MuiCollapse-entered {
  background-color: #1d1d1d1f !important;
}

.MuiDialogContent-root {
  background-color: #1d1d1d1f !important;
}

.makeStyles-root-1 .MuiDialogTitle-root {
  background-color: #1d1d1d1f !important;
}

.makeStyles-root-1 .MuiDialogContent-root .MuiListItem-root {
  padding: 8px !important;
  box-shadow: inset 0 0px 0 0 rgb(255 255 255 / 10%) !important;
}

.makeStyles-root-1 .MuiDialogContent-root .MuiList-root {
  background: #1d1d1d1f !important;
}

.mintButton {
  background-color: #1d1d1f;
  border-radius: 12px;
  font-size: 17px;
  line-height: 1.17648;
  letter-spacing: -0.022em;
  display: inline-block;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.text-span,
.text-span:hover {
  color: #1d1d1f;
  text-decoration: underline;
}

@media only screen and (max-width: 1023px) and (max-device-width: 736px) {
  .order-wrapper {
    overflow: auto;
  }
}

.mintButton:disabled {
  background-color: #86868b;
  border-radius: 12px;
  font-size: 17px;
  line-height: 1.17648;
  letter-spacing: -0.022em;
  display: inline-block;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
