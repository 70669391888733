.nav {
    background-color: hsla(0, 0%, 86%, .33);
    /*     border-bo: 1px solid hsla(0, 0%, 80%, .87); */
    position: sticky;
    top: 0;
    z-index: 9997;
    left: 0;
    width: 100%;
/*     transition: 1s ease; */
    height: 52px;
}

.navLogo {
    height: 44px;

}

.navItem {
    margin-left: 20px;
    float: left;
    list-style: none;
}

.navWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    z-index: 1;
}

.navBackground {
    backdrop-filter: saturate(180%) blur(20px);
      /*   background-color: rgba(255, 255, 255, 0.72); */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
}
.navBackground.show{
    background-color: rgba(210,210,210,0.9);
    transition: background-color 0.5s ease;
}
@supports ((-webkit-backdrop-filter: initial) or (backdrop-filter: initial)){
    .navBackground.show{
        background-color: rgba(210,210,210,0.9);
    }
}
@supports ((-webkit-backdrop-filter: initial) or (backdrop-filter: initial)){
    .navBackground.show{
/*         -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px); */
        background-color: rgba(210,210,210,0.9);
    }
}
.navBackground:after {
    margin-left: 0;
    left: 0;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    width: 980px;
    height: 1px;
    z-index: 1;
    background-color:hsla(0, 0%, 79.6%, 0.87);
/*     margin-left: -490px;
    left: 50%; */
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    height: 1px;
}
.navBackground.show:after {
    margin-left: 0;
    left: 0;
    width: 100%;
}
.lnAcitons{
    
    float: left;
    padding-top: 18px;
    margin-left:2px;
}
.lnActionMenucta {
    display: none;
}
@media only screen and (max-width: 1023px){
.navBackground:after {
    margin-left: 0;
    left: 0;
    width: 100%;
}
}
.navContent {
    margin: 0 auto;
    max-width: 980px;
    padding: 0 4px;
    position: relative;
    z-index: 2;

}
/* @supports (padding: calc(max(0px))) {
    .navContent {
        padding-left: calc(max(22px, env(safe-area-inset-left)));
        padding-right: calc(max(22px, env(safe-area-inset-right)));
    }
}
 */
 @media (max-width: 1068px){
 .navContent {
     padding-left:24px;
     padding-right:20px;
 }
}

.navContent:before {
    content: ' ';
    display: table;

}

.navTitle {
    font-size: 21px;
    line-height: 1.14286;
    font-weight: 600;
    letter-spacing: .011em;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    cursor: default;
    display: block;
    float: left;
    margin: 14px 0 -14px;
    padding: 0;
    height: 52px;
    white-space: nowrap;
    color: #000;
    transition: color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
}

.navTitle a {
    display: flex;
    flex-direction: column-reverse;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
    text-decoration: none;
    white-space: nowrap;
    opacity: .88;
}

.navContent a {
    display: block;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    transition: visibility 0s linear 1s;
    margin-left: 24px;
    float: left;
    list-style: none;
    color: #1d1d1f;
}

.navMenu {
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: -.01em;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    float: right;
    margin-top: -3px;
}

.navContent a:hover,
.navIcon:hover,
.searchIcon:hover {
    opacity: 0.7;
}

.navLinks {
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: -.01em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    float: right;
    margin-top: -3px;
}

.menuTray {
    float: left;
    padding-top: 18px;
}

.navMenu ul {
    list-style: none;
}
 .AclnButton {
    margin-top: -1px!important;
    cursor: pointer!important;
    display: inline-block!important;
    text-align: center!important;
    white-space: nowrap!important;
    background: #868686!important;
    color: #fff!important;
    border-radius: 980px!important;
    font-size: 14px!important;
    line-height: 1.33337!important;
    font-weight: 400!important;
    letter-spacing: -.01em!important;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif!important;
    min-width: 23px!important;
    padding-left: 11px!important;
    padding-right: 11px!important;
    padding-top: 4px!important;
    padding-bottom: 4px!important;
}
.ActionProduct{
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: auto;
    left: auto;
}
.menuItem {
    margin-left: 20px;
    float: left;
    list-style: none;
}
.buyButton{
    padding: 4px 11px;
    border-radius: 12px;
    background-color: #868686;
    color: #fff;
    font-size: 14px;
    line-height: 1.2;
}
.navLink {
    display: inline-block;
    line-height: 22px;
    white-space: nowrap;
}

.navIcon {
    display: none;
    grid-gap: 5px;
    grid-template-columns: auto;
    padding: 17px 0;
    /*  height: 10px; */
    cursor: pointer;
    transition: all 0.3s;
}

.bar {
    height: 1px;
    width: 18px;
    background: white;
    transition: 0.5s;
}

.show .one {
    transform: rotate(45deg) translateY(5.5px);
}

.show .two {
    transform: rotate(-45deg) translateY(-5.5px);
}

.lnMenucta{
    margin-right: -11px;
    cursor: pointer;
    display: block;
    overflow: hidden;
    width: 40px;
    height: 30px;
    -webkit-tap-highlight-color: transparent;    
  }
  .lnMenuctaChevron{
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1),transform-origin 1s cubic-bezier(0.86, 0, 0.07, 1);
        transform: translateY(0);
        opacity: 0.8;
  }
  .lnMenuctaChevron:hover {
    opacity: 1;
}
.lnMenuctaChevron.show{
    transform: translateY(-8px);
}
.lnMenuctaChevron::before{
    background: #000;    
    transform-origin: 100% 0%;
    transform: rotate(40deg) scaleY(1.1);
    content: "";
    right: 50%;
    border-radius: 0.5px 0 0 0.5px;
    display: block;
    position: absolute;
    top: 13px;
    width: 11px;
    height: 1px;
    z-index: 1;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1),transform-origin 1s cubic-bezier(0.86, 0, 0.07, 1);
}
.lnMenuctaChevron.show::before{
    transform-origin: 100% 0%;
    transform: rotate(-40deg) scaleY(1.1);
}
.lnMenuctaChevron.show::after{
    transform-origin: 0% 0%;
    transform: rotate(40deg) scaleY(1.1);
}
.lnMenuctaChevron::after{
    background: #000;    
    transform-origin: 0% 100%;
    transform: rotate(-40deg) scaleY(1.1);
    left: 50%;
    border-radius: 0 0.5px 0.5px 0;
    content: "";
    display: block;
    position: absolute;
    top: 13px;
    width: 11px;
    height: 1px;
    z-index: 1;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1),transform-origin 1s cubic-bezier(0.86, 0, 0.07, 1);
}
.lnAciton{
    margin-left: 20px;
    float: left;
}
.InActionButton{
    margin-left:0;
}
@media only screen and (max-width: 833px) {
    .AclnButton {
        margin-top: -5px!important;}
    .lnAcitons{
        right: 0;
    padding-left: 22px;
    padding-right: 20px;
    position: absolute;
    top: 0;
    z-index: 1;
    }
   .navMenu{
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
        letter-spacing: -.02em;
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        padding-top: 0;
        margin-top: 0;
        width: 100%;
    }
    .lnActionMenucta {
        display: block;
        float: left;
        margin-top: -2px;
        position: relative;
        z-index: 1;
    }
    .navBackground {
        min-height: 48px;
        transition: background-color 0.5s ease 0.7s;
    }
    .navTitle {
        font-size: 19px;
        line-height: 1.26316;
        font-weight: 600;
        letter-spacing: .012em;
        display: inline-block;
        margin: 12px 0 -12px;
        height: 48px;
    }
    .menuTray {
        float: none;
        overflow: hidden;
        pointer-events: none;
        visibility: hidden;
        width: 100%;
        padding-top: 0;
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0.28, 0.11, 0.32, 1) 0.4s, visibility 0s linear 1s;
    }
    .show .menuTray{
        max-height: 400px;
        max-height: calc(100vh - 48px - 48px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        pointer-events: auto;
        visibility: visible;
        transition-delay: 0.2s, 0s;
    }
    .menuItems {
        opacity: 0;
        padding: 4px 24px 24px;
        transform: translate3d(0, -150px, 0);
        transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s, opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
        margin:0;
    }
    .show .menuItems {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition-delay: 0.2s, 0.4s;
    }
    .menuItem {
        margin-left: 0;
        display: inline-block;
        float: none;
        width: 100%;
        height: 44px;
    }

   

    a.navItem{
        border-top: 1px solid hsla(0, 0%, 79.6%, 0.87) ;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-left:0!important;
        line-height: 1.3;
        opacity: 0;
        transform: translate3d(0, -25px, 0);
        transition: 0.5s ease;
        transition-property: transform, opacity;
    }

    .menuItem {
        margin-left: 0;
        display: inline-block;
        float: none;
        width: 100%;
        height: 44px;
    }
    .menuItem:nth-child(1) a.navItem {
        transition-delay: 0.07s;
    }
    .show .menuItem:nth-child(1) a.navItem {
        border-color: rgba(255,255,255,0.428571);
        transition-delay: 0.56s;
    }
    .show .menuItem a.navItem{
        transform: translate3d(0, 0, 0);
    }
    .menuItem:first-child a.navItem {
        border-top: 0;
            opacity: .92;
    }
    .menuItem a.navItem {
            opacity: .92;
    }
}
